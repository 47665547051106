'use client'

import Link from '@/components/Link/Link'
import styles from './Navigation.module.scss'
import useStore from '@/store'
import { useContext, useEffect, useRef, useState } from 'react'
import { GlobalSettingsContext } from '@/context/GlobalSettings'
import { DOC_TYPES } from '@/data'
import HeaderLogo from '@/components/_svgs/HeaderLogo'
import NavigationButtons from '@/components/Navigation/NavigationButtons/NavigationButtons'
import classNames from 'classnames'
import useCurrentPage from '@/hooks/use-current-page'
import Icon from '@/components/Icon/Icon'
import NavigationMobileDropdown from '@/components/Navigation/NavigationMobileDropdown/NavigationMobileDropdown'
import useBreakpoint from '@/hooks/use-breakpoint'
import useI18n from '@/hooks/use-i18n'

const TRANSPARENT_BG_SECTION_TYPES = ['homepageHero', 'mediaBackgroundAndTitle']
const OPAQUE_BG_SECTION_TYPES_MOBILE = ['mediaBackgroundAndTitle']
const HIDE_LOGO_AND_BUTTONS_TYPES = ['homepageHero']

export const NAV_ID = 'nav'

const Navigation = () => {
  const navTheme = useStore(state => state.navTheme)
  const navScrollingOverSection = useStore(state => state.navScrollingOverSection)
  const [currentNavTheme, setCurrentNavTheme] = useState<SectionBackground>(navTheme)
  const [currentScrollingOverSection, setCurrentScrollingOverSection] = useState<string | null>(null)
  const navThemeDebounceTimeout = useRef<ReturnType<typeof setTimeout> | null>()
  const { navigationData } = useContext(GlobalSettingsContext)
  const { currentSlug } = useCurrentPage()
  const navIsOpen = useStore(state => state.navIsOpen)
  const setNavIsOpen = useStore(state => state.setNavIsOpen)
  const { breakpoint } = useBreakpoint()
  const isLoading = breakpoint === null || navScrollingOverSection === null
  const { i18n } = useI18n()
  const { isMobile } = useBreakpoint()
  const isOpaqueMobileBg = isMobile && OPAQUE_BG_SECTION_TYPES_MOBILE.includes(currentScrollingOverSection as string)

  useEffect(() => {
    if (breakpoint?.name === 'desktop' || breakpoint?.name === 'xl') {
      setNavIsOpen(false)
    }
  }, [setNavIsOpen, breakpoint])

  useEffect(() => {
    if (navThemeDebounceTimeout.current) {
      clearTimeout(navThemeDebounceTimeout.current)
    }

    navThemeDebounceTimeout.current = setTimeout(() => {
      setCurrentNavTheme(navTheme)
      setCurrentScrollingOverSection(navScrollingOverSection)
    }, 50)
  }, [navTheme, navScrollingOverSection])

  if (!navigationData?.links?.length) return null

  return (
    <>
      <header
        id={NAV_ID}
        className={classNames(
          styles.Navigation,
          {
            [styles.isTransparentBg]:
              TRANSPARENT_BG_SECTION_TYPES.includes(currentScrollingOverSection as string) && !isOpaqueMobileBg,
          },
          {
            [styles.navIsOpen]: navIsOpen,
          },
          {
            [styles.hideLogoAndButtons]: HIDE_LOGO_AND_BUTTONS_TYPES.includes(currentScrollingOverSection as string),
          },
          {
            [styles.loading]: isLoading,
          },
        )}
        data-scrolling-over-section={currentScrollingOverSection}
        data-theme={currentNavTheme}
      >
        <nav className={styles.nav}>
          <Link
            link={{
              linkType: 'internal',
              link: {
                _type: DOC_TYPES.PAGE,
                slug: '',
              },
            }}
            className={styles.logoContainer}
            onClick={() => {
              setNavIsOpen(false)
            }}
            ariaLabel={i18n('ariaGoHome')}
          >
            <Icon
              name="footerSymbol"
              className={styles.logoMobile}
            />
            <HeaderLogo className={styles.logoDesktop} />
          </Link>
          <ul className={styles.desktopLinkList}>
            {navigationData.links.map((link, i) => {
              const internalLink = link.link as SanityLinkInternal
              return (
                <li
                  className={classNames(styles.desktopLinkList__item, {
                    [styles.isActive]: currentSlug === internalLink?.slug,
                  })}
                  key={i}
                >
                  <Link
                    link={link}
                    className={styles.desktopLinkList__item__link}
                  />
                </li>
              )
            })}
          </ul>
          <div className={styles.buttons}>
            <div className={styles.buttons__desktop}>
              <NavigationButtons currentScrollingOverSection={currentScrollingOverSection || ''} />
            </div>
            <button
              className={styles.mobileToggleButton}
              onClick={() => {
                setNavIsOpen(!navIsOpen)
              }}
              aria-label={i18n(navIsOpen ? 'ariaCloseNavigation' : 'ariaOpenNavigation')}
            >
              <span className={styles.mobileToggleButton__hamburgerContainer}>
                <Icon name="hamburger" />
              </span>
              <span className={styles.mobileToggleButton__closeContainer}>
                <Icon name="close" />
              </span>
            </button>
          </div>
        </nav>
      </header>
      <NavigationMobileDropdown
        scrollingOverSection={currentScrollingOverSection}
        navTheme={currentNavTheme}
      />
      <div
        className={classNames(
          {
            [styles.isTransparentBg]:
              TRANSPARENT_BG_SECTION_TYPES.includes(currentScrollingOverSection as string) && !isOpaqueMobileBg,
          },
          {
            [styles.navIsOpen]: navIsOpen,
          },
          {
            [styles.loading]: isLoading,
          },
          styles.headerBg,
        )}
        data-scrolling-over-section={currentScrollingOverSection}
        data-theme={currentNavTheme}
      />
    </>
  )
}

Navigation.displayName = 'Navigation'

export default Navigation
