import link from './link'

export const fields = `
  links[] {
    ${link.fields}
  }
`

export const fragment = (name = 'navigation') => `${name}{${fields}}`

const exported = {
  fields,
  fragment,
}

export default exported
