'use client'

import classnames from 'classnames'
import styles from './ContentWithStickyNavigationMobileMenu.module.scss'
import useStore, { StickyMobileNavActiveItemsByIdType, StickyMobileNavContentType } from '@/store'
import { CSSProperties, useCallback, useContext, useEffect, useRef, useState } from 'react'
import Icon from '@/components/Icon/Icon'
import { ScrollContext } from '@/context/Scroll'
import useBreakpoint from '@/hooks/use-breakpoint'
import useWindowResize from '@/hooks/use-window-resize'
import { NAV_ID } from '@/components/Navigation/Navigation'

const ContentWithStickyNavigationMobileMenu = ({
  items,
  container,
  bottomNavContent,
  sectionBackground,
}: StickyMobileNavContentType) => {
  const [activeId, setActiveId] = useState('')
  const unsubRef = useRef<ReturnType<typeof useStore.subscribe> | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const { scroll } = useContext(ScrollContext)
  const { breakpoint } = useBreakpoint()
  const isAllowedBreakpoint = breakpoint?.name !== 'desktop' && breakpoint?.name !== 'xl'
  const [offsets, setOffsets] = useState<{ top: number; bottom: number }>({ top: 0, bottom: 0 })
  const resizeKey = useWindowResize()
  const [scrollState, setScrollingState] = useState<'start' | 'fixed' | 'end'>('start')
  const { onScrollCallback } = useContext(ScrollContext)
  const [scrollCallbackId, setScrollCallbackId] = useState('')
  const resizeObserverRef = useRef<ResizeObserver | null>(null)

  useEffect(() => {
    setScrollCallbackId(`id_${Math.random()}`)
  }, [])

  const calculateOffsets = useCallback(() => {
    if (!container) return
    const offsetTop = container.getBoundingClientRect().top + document.documentElement.scrollTop
    const offsetBottom = offsetTop + container.offsetHeight

    setOffsets({
      top: offsetTop,
      bottom: offsetBottom,
    })
  }, [setOffsets, container])

  const handleNavigationClick = (id: string) => {
    const listElement = document.getElementById(id)
    if (!id || !scroll || !listElement) return

    scroll.start()
    setIsOpen(false)

    setTimeout(() => {
      if (listElement instanceof HTMLElement) {
        const offset = listElement.getBoundingClientRect().top + document.documentElement.scrollTop
        scroll.scrollTo(offset + 1, { duration: 0.6 })
      }
    }, 250)
  }

  useEffect(() => {
    return () => {
      onScrollCallback({
        key: scrollCallbackId,
        remove: true,
      })
    }
  }, [scrollCallbackId, onScrollCallback])

  useEffect(() => {
    if ((offsets.top === 0 && offsets.bottom === 0) || !onScrollCallback || !scrollCallbackId) return

    const headerElement = document.getElementById(NAV_ID)
    const headerHeight = headerElement?.offsetHeight
    if (!headerHeight) return

    onScrollCallback({
      key: scrollCallbackId,
      remove: true,
    })

    onScrollCallback({
      key: scrollCallbackId,
      callback: e => {
        const currentScroll = e.animatedScroll
        if (currentScroll < offsets.top - headerHeight) {
          setScrollingState('start')
        } else if (currentScroll > offsets.bottom - headerHeight) {
          setScrollingState('end')
        } else {
          setScrollingState('fixed')
        }
      },
    })
  }, [scroll, offsets, onScrollCallback, scrollCallbackId])

  useEffect(() => {
    calculateOffsets()
  }, [calculateOffsets, resizeKey])

  useEffect(() => {
    if (resizeObserverRef.current) {
      resizeObserverRef.current.unobserve(document.body)
    }

    // create an Observer instance
    resizeObserverRef.current = new ResizeObserver(() => {
      calculateOffsets()
    })

    // start observing a DOM node
    resizeObserverRef.current.observe(document.body)

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.unobserve(document.body)
      }
    }
  }, [calculateOffsets])

  useEffect(() => {
    if (!scroll) return
    scroll[isOpen && isAllowedBreakpoint ? 'stop' : 'start']()
  }, [isOpen, scroll, isAllowedBreakpoint])

  useEffect(() => {
    if (unsubRef.current) unsubRef.current()

    if (isAllowedBreakpoint) {
      unsubRef.current = useStore.subscribe(
        state => [state.stickyMobileNavActiveItemsById],
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (value: any) => {
          const itemsById = value[0]
          if (itemsById) {
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            const activeId = itemsById.find(
              (item: StickyMobileNavActiveItemsByIdType) => item.id === container.id,
            )?.activeId
            setActiveId(activeId || items[0].id)
          }
        },
      )
    }

    return () => {
      if (unsubRef.current) {
        unsubRef.current()
      }
    }
  }, [setActiveId, container.id, items, isAllowedBreakpoint])

  if (!activeId || !items.length || !container || !isAllowedBreakpoint) return null

  return (
    <div
      className={classnames(styles.ContentWithStickyNavigationMobileMenu, { [styles.isOpen]: isOpen })}
      data-theme={sectionBackground}
      data-scrolling-state={scrollState}
      style={
        {
          '--background-color': `var(--${sectionBackground})`,
          '--offset-top': `${offsets.top}px`,
          '--offset-bottom': `${offsets.bottom}px`,
        } as CSSProperties
      }
    >
      <button
        onClick={() => {
          setIsOpen(prev => !prev)
        }}
        className={styles.headerButton}
      >
        <span className={styles.headerButton__text}>{items.find(item => item.id === activeId)?.title}</span>
        <Icon
          name="caretDown"
          className={styles.headerButton__icon}
        />
      </button>
      <div className={styles.content}>
        <div
          className={styles.contentInner}
          data-lenis-prevent
        >
          <ul className={styles.linkList}>
            {items.map((item, i) => (
              <li
                key={i}
                className={classnames(styles.linkList__item, {
                  [styles.isActive]: activeId === item.id,
                })}
              >
                <button
                  onClick={() => {
                    handleNavigationClick(item.id)
                  }}
                  className={styles.linkList__itemButton}
                >
                  {item.title}
                </button>
              </li>
            ))}
          </ul>
          {bottomNavContent && <div className={styles.bottomContent}>{bottomNavContent}</div>}
        </div>
      </div>
    </div>
  )
}

ContentWithStickyNavigationMobileMenu.displayName = 'ContentWithStickyNavigationMobileMenu'

export default ContentWithStickyNavigationMobileMenu
