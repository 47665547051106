import { create } from 'zustand'
import { persist, subscribeWithSelector } from 'zustand/middleware'
import { Coordinate } from '@/types/GeneralTypes'
import { ContentWithStickyNavigationLineItem } from '@/components/ContentWithStickyNavigation/ContentWithStickyNavigation'

const STORAGE_KEY = 'DTF_STORAGE'

export type StickyMobileNavContentType = {
  items: ContentWithStickyNavigationLineItem[]
  container: HTMLElement
  bottomNavContent?: React.ReactNode
  sectionBackground: SectionBackground
}

export type StickyMobileNavActiveItemsByIdType = {
  id: string
  activeId: string
}

type StoreValues = {
  // Global Settings
  canInteract: boolean
  lastElementInFocus: HTMLElement | null

  // Navigation
  navIsOpen: boolean
  navTheme: SectionBackground
  navScrollingOverSection: string | null

  // Content with sticky nav
  stickyMobileNavContent: StickyMobileNavContentType[]
  stickyMobileNavActiveItemsById: StickyMobileNavActiveItemsByIdType[]

  // popout video
  popoutVideo: { videoType: SanityLinkVideoTypes; videoId: string } | null

  // Restaurant
  activeRestaurant: LocationHero | null
  favouriteRestaurant: LocationHero | null

  // Merch
  merchSlideoutData: SanityMerchandiseItem | null

  // Interaction
  scrollY: number
  mouseCoordinates: Coordinate
}

type StoreSetters = {
  // Global Settings
  setCanInteract: (value: StoreValues['canInteract']) => void
  setLastElementInFocus: (value: StoreValues['lastElementInFocus']) => void

  // Navigtion
  setNavIsOpen: (value: StoreValues['navIsOpen']) => void
  setNavTheme: (value: StoreValues['navTheme']) => void
  setNavScrollingOverSection: (value: StoreValues['navScrollingOverSection']) => void

  // Content with sticky nav
  setStickyMobileNavContent: (value: StickyMobileNavContentType) => void
  setStickyMobileNavActiveItemsById: (value: StickyMobileNavActiveItemsByIdType) => void
  removeStickyMobileNavById: (id: string) => void

  // popout video
  setPopoutVideo: (value: StoreValues['popoutVideo']) => void

  // Restaurant
  setActiveRestaurant: (value: StoreValues['activeRestaurant']) => void
  setFavouriteRestaurant: (value: StoreValues['favouriteRestaurant']) => void

  // Merch
  setMerchSlideoutData: (value: StoreValues['merchSlideoutData']) => void

  // Interaction
  setScrollY: (value: number) => void
  setMouseCoordinates: (value: Coordinate) => void
}

type CombinedStoreValuesAndSetters = StoreValues & StoreSetters

export const useStore = create(
  subscribeWithSelector(
    persist<CombinedStoreValuesAndSetters>(
      set => ({
        // Global Settings
        canInteract: true,
        setCanInteract: canInteract => set({ canInteract }),
        lastElementInFocus: null,
        setLastElementInFocus: lastElementInFocus => set({ lastElementInFocus }),

        // Restaurant
        activeRestaurant: null,
        setActiveRestaurant: activeRestaurant => set({ activeRestaurant }),
        favouriteRestaurant: null,
        setFavouriteRestaurant: favouriteRestaurant => set({ favouriteRestaurant }),

        // Popout video
        popoutVideo: null,
        setPopoutVideo: popoutVideo => set({ popoutVideo }),

        // Merch
        merchSlideoutData: null,
        setMerchSlideoutData: merchSlideoutData => set({ merchSlideoutData }),

        // Navigation
        navIsOpen: false,
        setNavIsOpen: navIsOpen => set({ navIsOpen }),
        navTheme: 'dough',
        setNavTheme: navTheme => set({ navTheme }),
        navScrollingOverSection: null,
        setNavScrollingOverSection: navScrollingOverSection => set({ navScrollingOverSection }),

        // Content with sticky nav
        stickyMobileNavContent: [],
        setStickyMobileNavContent: stickyMobileNavContent => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const hashMap: any = {}
          const itemContent = [...useStore.getState().stickyMobileNavContent, stickyMobileNavContent]
          itemContent.forEach(_itemContent => {
            if (_itemContent?.container?.id && _itemContent?.items?.length) {
              hashMap[_itemContent?.container?.id] = {
                items: _itemContent?.items,
                container: _itemContent?.container,
                bottomNavContent: _itemContent?.bottomNavContent ? _itemContent?.bottomNavContent : null,
                sectionBackground: _itemContent?.sectionBackground ? _itemContent?.sectionBackground : 'dough',
              }
            }
          })
          set({ stickyMobileNavContent: Object.values(hashMap) })
        },
        stickyMobileNavActiveItemsById: [],
        setStickyMobileNavActiveItemsById: value => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const hashMap: any = {}
          const itemContent = [...useStore.getState().stickyMobileNavActiveItemsById, value]

          itemContent.forEach(item => {
            if (item.id && item.activeId) {
              hashMap[item.id] = item
            }
          })

          set({ stickyMobileNavActiveItemsById: Object.values(hashMap) })
        },
        removeStickyMobileNavById: id => {
          if (!id) return

          const itemContent = [...useStore.getState().stickyMobileNavContent]

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const newContent: any = []
          itemContent.forEach(_itemContent => {
            if (_itemContent?.container?.id !== id) {
              newContent.push(_itemContent)
            }
          })

          set({ stickyMobileNavContent: newContent })
        },

        // Interaction
        scrollY: 0,
        setScrollY: (scrollY: number) => set({ scrollY }),
        mouseCoordinates: { x: 0, y: 0 },
        setMouseCoordinates: mouseCoordinates => set({ mouseCoordinates }),
      }),
      {
        name: STORAGE_KEY, // name of the item in the storage (must be unique)
        partialize: state => ({ favouriteRestaurant: state.favouriteRestaurant } as CombinedStoreValuesAndSetters),
      },
    ),
  ),
)

export default useStore
