import cmsSettings from '../cmsSettings'
import { headerWithCtas, imageAsset, videoAsset } from '@/data/sanity/fragments'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  'sectionBackground': 'black',
  "textColor": coalesce(textColor, 'dough'),
  eyebrow,
  ${headerWithCtas.fragment('headingContent')},
  mediaType,
  overlayOpacity,
  ${imageAsset.fragment('image')},
  ${videoAsset.fragment('videoLoopDesktop')},
  ${videoAsset.fragment('videoLoopMobile')}
`

export const fragment = (name = 'mediaBackgroundAndTitle') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
