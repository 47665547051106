import { LANGUAGES } from '@/data'

// prettier-ignore
const I18N_ENGLISH = {
  // Location
  phoneNumberShort: 'P. #{phoneNumber}',
  addressShort: 'A. #{address}',
  emailShort: 'E. #{email}',
  locationOpenUntil: 'Open until #{time}',
  locationClosed: 'Closed',
  locationLongName: '#{title} at #{areaTitle}',
  setAsPreferred: 'Set as Preferred',
  comingSoon: 'Coming Soon',
  beTheFirstToDine: 'Be among the first to dine with us in our brand new location, opening soon.',
  enterEmailBelow: 'Enter your email below to receive updates about our new location.',
  findALocation: 'Find a Location',
  holidayOpeningHours: 'Public Holiday Opening Hours',
  details: 'Details',
  notifyMeWhenOpening: 'Notify me about opening',
  newLocationOpeningSoon: 'New location opening soon',
  steamingSoon: 'Steaming Soon',

  // Misc Text
  readMore: 'Read More',
  reserve: 'Reserve',
  order: 'Order',
  goToLocation: 'Go to location',
  all: 'All',
  at: 'At',
  findUs: 'Find Us',
  goToCategory: 'Go to category',
  learnMore: 'Learn more',
  loadMore: 'Load More',
  scrollToNextSection: 'Scroll to next section',

  // timeline section
  travelToDate: 'Go to: #{date}',
  timelineYearAndTitleAria: '#{year}: #{title}',

  // Menu
  menu: 'Menu',
  menuAllergenText: 'While Din Tai Fung offers vegan, vegetarian and gluten-free dishes, please note that all items at our restaurants are prepared fresh using shared cooking equipment and facilities, and cross-contact with meat products and allergens may occur.',
  viewAllergenGuide: 'View Allergen Guide',

  // Services
  orderPickUp: 'Order Pick Up',
  orderCatering: 'Order Catering',
  orderDeliveryService: 'Order #{service}',
  generalReservation: 'Reserve a table',
  diningRoom: 'Reserve Dining Room',
  diningRoomManhattan: 'Reserve Dining Room',
  barCounter: 'Reserve Bar Counter',
  yelp: 'Yelp',
  uberEats: 'Uber Eats',
  caviar: 'Caviar',
  doorDash: 'DoorDash',
  grubHub: 'Grubhub',
  postmates: 'Postmates',

  // Newsletter
  signUp: 'Sign up',
  typeYourEmail: 'Type your email...',
  thankYou: 'Thank you for subscribing.',
  somethingWentWrong:
    'Something went wrong, Please attempt again at a later time or seek assistance from our support team',
  pleaseWait: 'Please Wait...',
  emailAlreadySubscribed: 'You\'re already in our email list',
  invalidEmailAddress: 'Please enter a valid email address.',
  signUpWithEmail: 'Sign Up With Email',
  welcomeEmailTemplateSubject: 'Welcome to Din Tai Fung Newsletter',

  // Merch
  fitAndDetails: 'Fit & Details',
  materialAndCare: 'Material & Care',
  soldOut: 'Sold Out',
  soldOutComingBackSoon: 'Sold Out - New Items coming soon',
  merchComingSoon: 'Coming Soon',

  // Ecommerce Item
  viewOnWebsite: 'View on #{website}',
  goldbelly: 'Goldbelly',

  // Contact Form
  firstName: 'First Name *',
  lastName: 'Last Name *',
  reasonForContactingUs: 'Reason for contacting us *',
  locationVisited: 'Location Visited',
  emailAddress: 'Email Address *',
  phoneNumber: 'Phone: (123) 123-4567 *',
  dateOfVisit: 'When: YYYY/MM/DD',
  timeOfVisit: 'Time of Visit: HH:MM',
  message: 'Message *',
  submit: 'Submit',
  restaurantExperience: 'Restaurant Experience',
  mediaInquiries: 'Media Inquiries',
  DonationRequests: 'Donation Requests',
  businessRequests: 'Business Requests',
  generalQuestions: 'General Questions',
  other: 'Other',
  californiaArcadia: 'California - Arcadia',
  californiaCostaMesa: 'California - Costa Mesa',
  californiaDowntownDisney: 'California - Downtown Disney',
  californiaGlendale: 'California - Glendale',
  californiaLosAngeles: 'California - Los Angeles',
  californiaSanDiego: 'California - San Diego',
  californiaSantaClara: 'California - Santa Clara',
  californiaSantaMonica: 'California - Santa Monica',
  californiaTorrance: 'California - Torrance',
  nevadaLasVegas: 'Nevada - Las Vegas',
  newYorkNyc: 'New York - NYC',
  oregonPortland: 'Oregon - Portland',
  oregonTigard: 'Oregon - Tigard',
  washingtonBellevue: 'Washington - Bellevue',
  washingtonSeattlePacificPlace: 'Washington - Seattle Pacific Place',
  washingtonSeattleUniversityVillage: 'Washington - Seattle University Village',
  washingtonTukwila: 'Washington - Tukwila',
  thankyouForContactingUs: 'Thank you for contacting us!',
  dinTaiFungFromName: 'Din Tai Fung',
  dinTaiFungWebsiteContactForm: 'Din Tai Fung Website Contact Form',
  titleAndRestaurantText: '#{title} Restaurant',

  // Contact Form Messages
  invalidPhoneNumber: 'Please enter a valid phone number. Eg: (123) 123-4567',
  invalidDateOfVisit: 'Please enter a valid date',
  invalidTimeOfVisit: 'Please enter a valid time',
  requiredField: 'Please fill out this field',
  defaultFieldError: 'Field Error',
  pleaseFillTheRequiredFields: 'Please fill the required fields',
  thankYouForContactingUs: 'Thank you for contacting us. Our team will get back to you shortly.',
  thankYouPageTemplate: 'contact_form_thank_you', // use template name according to language
  emailSubject: 'Din Tai Fung Website Contact Form',
  emailTemplateSubject: 'Thank you for contacting Din Tai Fung',

  // Text and Image menu item defaults
  menuTextAndImageDefaultTitle: 'Quality is life',
  menuTextAndImageDefaultDescription:
    'Din Tai Fung takes the same painstaking care over each step in the process of bringing outstanding gourmet food to our customers.',
  menuTextAndImageDefaultLinkTitle: 'Find a location',

  //404
  returnHome: 'Return home',
  sorryWeCantFindThePage: 'Sorry, we can\'t seem to find the page you’re looking for.',
  pageNotFound: 'Page Not Found',

  // days
  monday: 'Monday',
  mondayShort: 'Mon',
  tuesday: 'Tuesday',
  tuesdayShort: 'Tues',
  wednesday: 'Wednesday',
  wednesdayShort: 'Wed',
  thursday: 'Thursday',
  thursdayShort: 'Thurs',
  friday: 'Friday',
  fridayShort: 'Fri',
  saturday: 'Saturday',
  saturdayShort: 'Sat',
  sunday: 'Sunday',
  sundayShort: 'Sun',

  // Event details section
  eventDetails: 'Event Details',
  enterInviteCode: 'Enter invite code...',
  inviteCodeInvalid: 'The invite code entered is invalid.',

  // Aria
  ariaGoHome: 'Go to home page',
  ariaVisitLink: 'Visit website link',
  ariaGoToPressRelease: 'Learn more about this press release from #{year}',
  ariaGoToSlideNumber: 'Go to slide #{slideNumber}',
  ariaBreadcrumbs: 'Breadcrumbs',
  ariaViewImageNumber: 'View image number #{number}',
  ariaOpenNavigation: 'Open Navigation',
  ariaCloseNavigation: 'Close Navigation',

  // states
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'American Samoa',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FM: 'Federated States Of Micronesia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    MP: 'Northern Mariana Islands',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Virgin Islands',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
    AB: 'Alberta',
    BC: 'British Columbia',
    MB: 'Manitoba',
    NB: 'New Brunswick',
    NF: 'Newfoundland',
    NT: 'Northwest Territories',
    NS: 'Nova Scotia',
    ON: 'Ontario',
    PE: 'Prince Edward Island',
    QC: 'Quebec',
    SK: 'Saskatchewan',
    YT: 'Yukon Territory',
}

const I18N_TW = {
  phoneNumberShort: 'P. #{phoneNumber}',
  addressShort: 'A. #{address}',
  emailShort: 'E. #{email}',
  locationOpenUntil: '#{time} 前開放',
  locationClosed: '已關閉',
  locationLongName: '#{areaTitle} 的 #{title}',
  setAsPreferred: '設為偏好',
  comingSoon: '即將開業',
  beTheFirstToDine: '成為首批在我們即將開業的新店址用餐的貴賓。',
  enterEmailBelow: '請在下方輸入您的電子郵件地址，接收關於我們新店址的最新資訊。',
  findALocation: '搜尋位置',
  holidayOpeningHours: '國定假日營業時間',
  readMore: '瞭解更多',
  reserve: '預約',
  order: '點餐',
  goToLocation: '前往位置',
  all: '全部',
  at: '位於',
  findUs: '搜尋我們',
  goToCategory: '轉至類別',
  learnMore: '瞭解更多',
  loadMore: '加載更多',
  travelToDate: '轉至：#{date}',
  menu: '菜單',
  viewAllergenGuide: '查看過敏源指南',
  orderPickUp: '訂購外帶',
  orderCatering: '訂購餐飲',
  orderDeliveryService: '訂單 #{service}',
  generalReservation: '預訂桌位',
  diningRoom: '預訂餐廳包廂',
  diningRoomManhattan: '預訂餐廳座位',
  barCounter: '預訂吧檯',
  yelp: '點評',
  uberEats: '訂購UBER EATS',
  caviar: 'Caviar',
  doorDash: 'DoorDash',
  grubHub: 'Grubhub',
  postmates: 'Postmates',
  signUp: '註冊',
  typeYourEmail: '請輸入您的電子郵件...',
  thankYou: '感謝您的訂閱。',
  somethingWentWrong: '發生了一些問題，請稍後再試一次，或尋求我們支援團隊的協助',
  pleaseWait: '請稍候...',
  emailAlreadySubscribed: '您已加入我們的電子郵件清單',
  invalidEmailAddress: '請輸入有效的電子郵件地址。',
  signUpWithEmail: '使用電子郵件註冊',
  fitAndDetails: '樣式與細節',
  materialAndCare: '材料與保養',
  viewOnWebsite: '造訪 #{website} 查看',
  goldbelly: 'Goldbelly',
  firstName: '名字',
  lastName: '姓氏',
  reasonForContactingUs: '與我們聯絡的原因',
  locationVisited: '光顧的店址',
  emailAddress: '電子郵件地址',
  phoneNumber: '電話：(123) 123-4567',
  dateOfVisit: '時間：年/月/日',
  timeOfVisit: '光顧時間：時：分',
  message: '訊息',
  submit: '提交',
  restaurantExperience: '餐廳體驗',
  mediaInquiries: '媒體諮詢',
  DonationRequests: '捐贈申請',
  businessRequests: '業務申請',
  generalQuestions: '常見問題',
  other: '其他',
  californiaArcadia: '加州 - 阿卡迪亞',
  californiaCostaMesa: '加州 - 科斯塔梅薩',
  californiaDowntownDisney: '加州 - 迪士尼城中區特區',
  californiaGlendale: '加州 - 格倫代爾',
  californiaLosAngeles: '加州 - 洛杉磯',
  californiaSanDiego: '加州 - 聖地牙哥',
  californiaSantaClara: '加州 - 聖塔克拉拉',
  californiaSantaMonica: '加州 - 聖塔莫尼卡',
  californiaTorrance: '加州 - 托倫斯',
  nevadaLasVegas: '內華達州 - 拉斯維加斯',
  newYorkNyc: '紐約州 - 紐約',
  oregonPortland: '奧勒岡州 - 波特蘭',
  oregonTigard: '奧勒岡州 - 泰格德',
  washingtonBellevue: '華盛頓州 - 貝爾維尤',
  washingtonSeattlePacificPlace: '華盛頓州 - 西雅圖太平洋廣場',
  washingtonSeattleUniversityVillage: '華盛頓州 - 西雅圖大學村購物區',
  washingtonTukwila: '華盛頓州 - 塔克維拉',
  thankyouForContactingUs: '感謝您與我們聯絡！',
  dinTaiFungFromName: '鼎豐泰',
  dinTaiFungWebsiteContactForm: '鼎豐泰網站聯絡表',
  invalidPhoneNumber: '請輸入有效的電話號碼。例如：(123) 123-4567',
  invalidDateOfVisit: '請輸入有效的日期',
  invalidTimeOfVisit: '請輸入有效的時間',
  requiredField: '請填寫此欄位',
  defaultFieldError: '欄位錯誤',
  pleaseFillTheRequiredFields: '請填寫必填欄位',
  thankYouForContactingUs: '感謝您與我們聯絡。我們的團隊會盡快回復您。',
  menuTextAndImageDefaultTitle: '品質就是生命',
  menuTextAndImageDefaultDescription:
    '鼎泰豐對每一個步驟都抱持著同樣的細心與用心，確保為每一位顧客呈現出頂級美食的風味。',
  menuTextAndImageDefaultLinkTitle: '搜尋位置',
  monday: '星期一',
  mondayShort: '週一',
  tuesday: '星期二',
  tuesdayShort: '週二',
  wednesday: '星期三',
  wednesdayShort: '週三',
  thursday: '星期四',
  thursdayShort: '週四',
  friday: '星期五',
  fridayShort: '週五',
  saturday: '星期六',
  saturdayShort: '週六',
  sunday: '星期日',
  sundayShort: '週日',
  eventDetails: '活動詳細資訊',
  enterInviteCode: '活動邀請代碼...',
  inviteCodeInvalid: '輸入的邀請代碼無效。',
  returnHome: '返回主頁',
  sorryWeCantFindThePage: '抱歉，找不到您搜尋的頁面。',
  pageNotFound: '找不到頁面',
  soldOutComingBackSoon: '已售罄，即將補貨',
  ariaGoHome: '前往主頁',
  ariaGoToPressRelease: '了解更多關於此新聞稿的內容：#{year}',
  ariaGoToSlideNumber: '轉至投影片 #{slideNumber}',
  AL: '阿拉巴馬州',
  AK: '阿拉斯加州',
  AS: '美屬薩摩亞',
  AZ: '亞利桑那州',
  AR: '阿肯色州',
  CA: '加州',
  CO: '科羅拉多州',
  CT: '康乃狄克州',
  DE: '特拉華州',
  DC: '哥倫比亞特區',
  FM: '密克羅尼西亞聯邦',
  FL: '佛羅里達州',
  GA: '喬治亞州',
  GU: '關島',
  HI: '夏威夷',
  ID: '愛達荷州',
  IL: '伊利諾州',
  IN: '印第安納州',
  IA: '愛荷華州',
  KS: '堪薩斯州',
  KY: '肯塔基州',
  LA: '路易斯安那州',
  ME: '緬因州',
  MH: '馬紹爾群島',
  MD: '馬里蘭州',
  MA: '麻薩諸塞州',
  MI: '密西根州',
  MN: '明尼蘇達州',
  MS: '密西西比州',
  MO: '密蘇里州',
  MT: '蒙大拿州',
  NE: '內布拉斯加州',
  NV: '內華達州',
  NH: '新罕布夏州',
  NJ: '紐澤西州',
  NM: '新墨西哥州',
  NY: '紐約州',
  NC: '北卡羅來納州',
  ND: '北達科他州',
  MP: '北馬里亞納群島',
  OH: '俄亥俄州',
  OK: '奧克拉荷馬州',
  OR: '奧勒岡州',
  PW: '帛琉',
  PA: '賓夕法尼亞州',
  PR: '波多黎各',
  RI: '羅德島州',
  SC: '南卡羅來納州',
  SD: '南達科他州',
  TN: '田納西州',
  TX: '德克薩斯州',
  UT: '猶他州',
  VT: '佛蒙特州',
  VI: '美屬維京群島',
  VA: '維吉尼亞州',
  WA: '華盛頓',
  WV: '西維吉尼亞州',
  WI: '威斯康辛州',
  WY: '懷俄明州',
  AB: '亞伯達省',
  BC: '英屬哥倫比亞省',
  MB: '曼尼托巴省',
  NB: '新布藍茲維省',
  NF: '紐芬蘭自治領',
  NT: '西北地方 (加拿大)',
  NS: '新斯科細亞省',
  ON: '安大略省',
  PE: '愛德華王子島省',
  QC: '魁北克省',
  SK: '薩克其萬省',
  YT: '育空地區',
}

export const I18N_TEXT = {
  [LANGUAGES.EN]: {
    ...I18N_ENGLISH,
  },
  [LANGUAGES.TW]: {
    ...I18N_ENGLISH,
    ...I18N_TW,
  },
}
